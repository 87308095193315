/* .intro-container {
	width: 90%;
	margin: auto;
} */

.presentation-par {
	text-align: center;
	font-size: larger;
}

.btn-second-blue {
	background-color: #5bc6e6;
}

.profile-img {
	border-radius: 10%;
}