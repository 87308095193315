.button {
	background-color: #ec9704;
	color: white;
	font-size: 40px;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
}
.intro {
	background-color: #f0f0f0;
	color: #333;
	padding: 20px;
	font-size: 24px;
	line-height: 1.5;
	text-align: center;
	margin-bottom: 40px;
	width: 100%;
}
.content {
	width: 100%;
	margin: auto;
}
