



@media only screen and (max-width: 767px){
    .card-btn {
        width:130px;
        height: 50px;
        padding:12px 33px !important
    }
    }

@media only screen and (max-width: 767px){
        .card-btn-info {
            width:140px;
            height: 50px;
            padding:12px 1px !important
        }
        }

@media only screen and (max-width: 767px){
            .card-btn-contact {
              
                padding:12px 33px !important
            }
            }
     
 @media only screen and (max-width: 767px){
        .card-txt {
           font-size: smaller;
        }
        }