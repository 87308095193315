.cv {
	margin-left: 25%;
	padding-bottom: 5%;
}

.job {
padding-top:2px;
padding-bottom:5px;
font-size: larger;

}

.cv-btn {
	border: none;
	background-color: transparent;
	color: #e2b007;
	margin-bottom: 5vh;
	font-size: medium;
}
