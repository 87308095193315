.footer {
	background-color: #ccd5ae;
	color: #fff;
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: bottom;
	bottom: 0;
	width: 100%;
	font-size: 40px;
}

.social-icons {
	display: flex;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
 	font-size: 40px;
	
}

.social-icons a {
	margin: 0 10px;
	color: #1d7c99;
}

.social-icons a:hover {
	color: #e2b007;
}

.social-icons p {
	margin-left: 10px;
	font-size: 1rem;
	align-items: center;
	display: flex;
	margin-bottom: -16px;
	color: black;
}

.social-icons li {
	display: inline-block;
padding:0;
	
 }

 
.copyright {
	padding-top: 20px;
}
