

.navbar {
	background-color: #ffffff;
	color: #1d7c99;
	font-size: large;
	display: flex;
	justify-content: left;
	align-content: flex-start;
	padding-right: 3rem ;
	padding-left: 3rem ;
}

@media only screen and (max-width: 290px){
	.navbar {
		
	padding-right: 1rem ;
	padding-left: 1rem ;
	}
	}


ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

ul li {
	display: inline-block;
	padding: 10px 20px;
}



/*Toggle underline on hover */

.nav-item {
	text-decoration: none;
	font-size: 20px;
	font-weight: bold;
	color: #1d7c99;
	position: relative;
	
}

ul li a::before {
	content: '';
	width: 0px;
	height: 10px;
	background: #1d7c99;
	position: absolute;
	top: 100%;
	left: 0;
	transition: 0.5s;
}

ul li a:hover::before {
	width: 50%;
	transform: translateX(50%);
}


@media only screen and (max-width: 991px){
ul li a:hover::before {
	width:0;
	
}
}
/*End of Toggle underline on hover */

.nav-link {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-decoration: none;
	text-align: center;
	
}

#navbar-svg {
	width: 3em;
	height: 3em;
}
@media only screen and (max-width: 1100px){
#navbar-svg {
	width: 2.5em;
}
}



@media only screen and (max-width: 991px){
.nav-link {
	align-items: end;
}
}

@media only screen and (max-width: 991px){
	#svg-home {
	align-items: center;
	};

}

.active {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-decoration: none;
	text-align: center;
	
}



.nav-icon {
	font-size: 4rem;
	margin-top: 1rem;
	margin-bottom: 0.1rem;
	color: #1d7c99;
}

.nav-text {
	font-size: 1.5rem;
	font-weight: bold;
	color: #1d7c99;
}

@media only screen and (max-width: 991px){
	.nav-text {
		font-size: 1.2rem;
	}
	}

.logo {
	margin-right: 10px;
	/* add the following styles to move the logo to the right */
	display: flex;
	justify-content: flex-end;
	flex: 1;
}

/* media query for small screens */
@media only screen and (max-width: 600px) {
	.navbar {
		flex-direction: column;
		align-items: center;
		margin-left: 0;
	}

	ul li {
		display: block;
		text-align: center;
		margin: 10px 0;
	}

	/* hide the nav-icons */
	.nav-icon {
		display: none;
	}

	/* show the hamburger menu */
	.menu-icon {
		display: block;
		font-size: 2rem;
		cursor: pointer;
		margin-right: 10px;
	}

	/* hide the menu by default */
	.menu {
		display: none;
	}

	/* show the menu when the hamburger menu is clicked */
	.menu.show {
		display: block;
	}
}
